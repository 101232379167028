import React from 'react'

import Layout from "../components/layout.js"
import SEO from "../components/seo"

export default () => (
    <Layout page="resources">
        <SEO title='Resources' description="Resources"/>
        <h1>✨Resources</h1>
        <p>This page contains links to free resources that I've found helpful in the past!</p>
        <section>
            <h2>🐱‍💻Coding</h2>
            <ul>
                <li><a target="_blank" href="https://revelry.co/development-programming-languages/">A Guide to Web Development and Programming Languages</a></li>
                <li><a target="_blank" href="https://www.freecodecamp.org/">FreeCodeCamp</a></li>
                <li><a target="_blank" href="https://www.theodinproject.com/">The Odin Project</a></li>
                <li><a target="_blank" href="https://www.pythonlikeyoumeanit.com/">Python Like You Mean It</a></li>
            </ul>
        </section>
        <section>
            <h2>🔨PLTW</h2>
            <ul>
                <li><a target="_blank" href="https://s3-us-west-1.amazonaws.com/assets.wagenvoord.dev/pltw/PLTWSyllabusAbridged.pdf">Syllabus</a></li>
                <li><a target="_blank" href="https://www.instructables.com/">Instructables</a></li>
                <li><a target="_blank" href="https://www.lahsa.org/documents">Los Angeles Homeless Services Authority (LAHSA) Document Directory</a></li>
            </ul>
        </section>
    </Layout>
)